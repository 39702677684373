/*! Generated by Font Squirrel (https://www.fontsquirrel.com) on August 28, 2022 */



@font-face {
    font-family: 'montblack';
    src: url('mont-black-webfont.woff2') format('woff2'),
         url('mont-black-webfont.woff') format('woff');
    font-weight: normal;
    font-style: normal;

}




@font-face {
    font-family: 'montblack_italic';
    src: url('mont-blackitalic-webfont.woff2') format('woff2'),
         url('mont-blackitalic-webfont.woff') format('woff');
    font-weight: normal;
    font-style: normal;

}




@font-face {
    font-family: 'montbold';
    src: url('mont-bold-webfont.woff2') format('woff2'),
         url('mont-bold-webfont.woff') format('woff');
    font-weight: normal;
    font-style: normal;

}




@font-face {
    font-family: 'montbold_italic';
    src: url('mont-bolditalic-webfont.woff2') format('woff2'),
         url('mont-bolditalic-webfont.woff') format('woff');
    font-weight: normal;
    font-style: normal;

}




@font-face {
    font-family: 'montextralight';
    src: url('mont-extralight-webfont.woff2') format('woff2'),
         url('mont-extralight-webfont.woff') format('woff');
    font-weight: normal;
    font-style: normal;

}




@font-face {
    font-family: 'montextralight_italic';
    src: url('mont-extralightitalic-webfont.woff2') format('woff2'),
         url('mont-extralightitalic-webfont.woff') format('woff');
    font-weight: normal;
    font-style: normal;

}




@font-face {
    font-family: 'montheavy';
    src: url('mont-heavy-webfont.woff2') format('woff2'),
         url('mont-heavy-webfont.woff') format('woff');
    font-weight: normal;
    font-style: normal;

}




@font-face {
    font-family: 'montheavy_italic';
    src: url('mont-heavyitalic-webfont.woff2') format('woff2'),
         url('mont-heavyitalic-webfont.woff') format('woff');
    font-weight: normal;
    font-style: normal;

}




@font-face {
    font-family: 'montlight';
    src: url('mont-light-webfont.woff2') format('woff2'),
         url('mont-light-webfont.woff') format('woff');
    font-weight: normal;
    font-style: normal;

}




@font-face {
    font-family: 'montlight_italic';
    src: url('mont-lightitalic-webfont.woff2') format('woff2'),
         url('mont-lightitalic-webfont.woff') format('woff');
    font-weight: normal;
    font-style: normal;

}




@font-face {
    font-family: 'montregular';
    src: url('mont-regular-webfont.woff2') format('woff2'),
         url('mont-regular-webfont.woff') format('woff');
    font-weight: normal;
    font-style: normal;

}




@font-face {
    font-family: 'montregular_italic';
    src: url('mont-regularitalic-webfont.woff2') format('woff2'),
         url('mont-regularitalic-webfont.woff') format('woff');
    font-weight: normal;
    font-style: normal;

}