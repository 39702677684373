@import url('https://fonts.googleapis.com/css2?family=Paytone+One&display=swap');
@import url('../src/fonts/Mont/stylesheet.css');

body {
  margin: 0;
  font-family: 'Mont', sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  overflow-x: hidden;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

.logo{
  font-family: 'Mont', sans-serif;
}

.button-quote, .button-quote::before{
  background: linear-gradient(257.43deg, #0D6AF5 13.46%, #13CDF6 81.11%);
  cursor: pointer;
}

.big, h1, h2, h4{
  font-weight: 900!important;
}

.semibold{
  font-weight: 700;
}

.wraptest{
  cursor: pointer;
}

a{
  text-decoration: none!important;
}

.rs-2{
  margin-top: 0!important;
}

footer > div > div > h4{
  margin-bottom: 0;
}

ul > a{
  color: black;
}

.ligne-bottom{
  height: 2px;
  background-color: #939393;
}

.country-details{
  top: 70px;
  right: 0;
  z-index: 10000;
}

.hovered:hover{
  background-color: #f5f5f5;
}

.hovered{
  transition: all .5s;
  -webkit-transition: all .5s;
  -moz-transition: all .5s;
  -ms-transition: all .5s;
  -o-transition: all .5s;
}

.detailProject{
  left: 0;
  top: 0;
  width: 100vw;
  height: 100vh;
  z-index: 100000;
}

.right-0{
  right: 0;
}

.closeCircle{
  transition: all .5s;
  -webkit-transition: all .5s;
  -moz-transition: all .5s;
  -ms-transition: all .5s;
  -o-transition: all .5s;
}

.closeCircle:hover{
  color: #03D688;
}

.pressed{
  transition: transform .2s;
  -webkit-transition: transform .2s;
  -moz-transition: transform .2s;
  -ms-transition: transform .2s;
  -o-transition: transform .2s;
}

.h-15{
  height: 15%;
}

.pressed:active{
  transform: scale(.95);
  -webkit-transform: scale(.95);
  -moz-transform: scale(.95);
  -ms-transform: scale(.95);
  -o-transform: scale(.95);
}

.detailProject > div{
  width: 1000px;
}

.left{
  width: 60%;
}

.right{
  width: 40%;
  padding-left: 30px;
}

.filter-opac{
  filter: opacity(0.5);
  transition: all .5s;
  -webkit-transition: all .5s;
  -moz-transition: all .5s;
  -ms-transition: all .5s;
  -o-transition: all .5s;
}

.ligne-bottom > div{
  height: 4px;
  transition: all .8s;
  background-color: #000000;
  border-radius: 0px 5px 5px 0px;
  -webkit-border-radius: 0px 5px 5px 0px;
  -moz-border-radius: 0px 5px 5px 0px;
  -ms-border-radius: 0px 5px 5px 0px;
  -o-border-radius: 0px 5px 5px 0px;
  -webkit-transition: all .8s;
  -moz-transition: all .8s;
  -ms-transition: all .8s;
  -o-transition: all .8s;
}

h2{
  margin-top: 30px!important;
  margin-bottom: 30px!important;
}

.upper{
  text-transform: uppercase;
  font-size: 20px;
}

.nameWork{
  font-size: 20px;
  margin-top: 10px;
  font-weight: 700;
}

.seeall{
  padding: 10px 30px 10px 30px;
}

.partir{
  font-size: 20px;
  font-weight: 600;
}

.price{
  font-size: 40px;
}

.button-quote > span{
  z-index: 10;
  position: relative;
}

.button-quote::before{
  content: '';
  position: absolute;
  left: 0;
  bottom: 0;
  width: 100%;
  height: 100%;
  filter: blur(25px);
  -webkit-filter: blur(25px);
  transform: translateY(5px) scale(.8);
  -webkit-transform: translateY(5px) scale(.8);
  -moz-transform: translateY(5px) scale(.8);
  -ms-transform: translateY(5px) scale(.8);
  -o-transform: translateY(5px) scale(.8);
}

.cursor-pointer{
  cursor: pointer;
}

li{
  list-style-type: none;
}

header{
  height: 100vh;
}

.button-quote2{
  margin-top: 100px;
}

.link {
  left: -430px;
  bottom: 1px;
}

.rounded-big{
  border-radius: 30px;
}

.badge-primary{
  padding-left: 30px;
  padding-top: 5px;
  padding-bottom: 5px;
  padding-right: 30px;
  border-radius: 60px;
  font-size: 20px;
  color: #4241EF!important;
  background-color: #EFEFFF;
  -webkit-border-radius: 60px;
  -moz-border-radius: 60px;
  -ms-border-radius: 60px;
  -o-border-radius: 60px;
}

.text-success{
  color: #03D688;
}

.card-service{
  width: 300px;
  padding: 20px;
  background-color: #ffffff40;
}

.swiper-slide{
  transition: all .4s!important;
  -webkit-transition: all .4s!important;
  -moz-transition: all .4s!important;
  -ms-transition: all .4s!important;
  -o-transition: all .4s!important;
  text-align: center;
  width: auto!important;
}

.bolden{
  font-weight: 600;
}

.mr-2{
  margin-right: 20px;
}

.swiper-slide:hover{
  background: #1592ff0f;
}

#root{
  background-image: url('./assets/bgbig.png');
  background-size: 100% 100%;
}

.why{
  background-color: #ffffff42;
  width: auto;
  max-width: 1000px;
}

.services{
  padding-top: 40px;
  padding-bottom: 40px;
  background-size: contain;
  background-repeat: no-repeat;
}

.img-card-service{
  margin-bottom: -50px;
  margin-top: -50px;
}

.rs > a{
  padding-right: 20px;
  padding-top: 40px;
  transition: all .3s;
  -webkit-transition: all .3s;
  -moz-transition: all .3s;
  -ms-transition: all .3s;
  -o-transition: all .3s;
}

.rs{
  margin-top: 3rem;
}

.rs > a:hover{
  filter: drop-shadow(2px 4px 4px black);
  -webkit-filter: drop-shadow(2px 4px 4px black);
}

.left-header{
  padding-right: 40px;
}

.header-ctn{
  height: calc(100% - 75px);
}

h3{
  font-size: 30px;
}

header > div:nth-child(2){
  z-index: 10;
}

.back-header{
  left: 0;
  top: 0;
  z-index: 1;
}

.bubble{
  filter: blur(80px);
  -webkit-filter: blur(80px);
}

.right-header{
  width: 500px;
  height: 700px;
}

.pp{
  left: 0;
  top: -110px;
}

.fromMenu2{
  top: 94px;
  z-index: 10000;
  background-color: #ffffff42;
  backdrop-filter: blur(30px);
  animation: .4s ease-in-out height;
  -webkit-animation: .4s ease-in-out height;
  overflow: hidden;
}

.cards-header{
  padding-left: 40px;
  padding-right: 40px;
  border-radius: 20px;
}

#root{
  padding-left: 50px;
  padding-bottom: 50px;
  padding-right: 50px;
}

#burger{
  display: none;
  height: 94px;
  z-index: 10000;
}

.cards-header1{
  left: 70px;
  top: 180px;
  animation: .7s ease-in-out infinite alternate floating;
  -webkit-animation: .7s ease-in-out infinite alternate floating;
  transform: rotateZ(20deg);
  -webkit-transform: rotateZ(20deg);
  -moz-transform: rotateZ(20deg);
  -ms-transform: rotateZ(20deg);
  -o-transform: rotateZ(20deg);
  -webkit-border-radius: 20px;
  -moz-border-radius: 20px;
  -ms-border-radius: 20px;
  -o-border-radius: 20px;
}
.cards-header3{
  right: -20px;
  top: 180px;
  animation: .7s ease-in-out infinite alternate floating;
  -webkit-animation: .7s ease-in-out infinite alternate floating;
  transform: rotateZ(-10deg);
  -webkit-transform: rotateZ(-10deg);
  -moz-transform: rotateZ(-10deg);
  -ms-transform: rotateZ(-10deg);
  -o-transform: rotateZ(-10deg);
  -webkit-border-radius: 20px;
  -moz-border-radius: 20px;
  -ms-border-radius: 20px;
  -o-border-radius: 20px;
}

.burger-1{
  width: 40px;
  height: 3px;
  background-color: #0f0f0f;
}

.loader{
  animation: .7s ease-in-out infinite alternate rotating;
  -webkit-animation: .7s ease-in-out infinite alternate rotating;
}

.burger-2{
  width: 20px;
  height: 3px;
  background-color: #0f0f0f;
}

#navbar{
  padding-top: 10px;
  padding-bottom: 10px;
  background-color: transparent;
  height: 94px;
}

.burger-desc{
  height: calc(100vh - 94px);
}

.cards-header-hor{
  padding: 20px;
  border-radius: 20px;
}

.rate{
  color: #e87000
}

.norate{
  color: #e1e1e1
}

.opacity{
  filter: opacity(0.5);
}

.transition-all{
  transition: all .4s;
  -webkit-transition: all .4s;
  -moz-transition: all .4s;
  -ms-transition: all .4s;
  -o-transition: all .4s;
}

.cards-header2{
  left: 450px;
  top: 350px;
  animation: .7s ease-in-out infinite alternate floating2;
  -webkit-animation: .7s ease-in-out infinite alternate floating2;
  transform: rotateZ(350deg);
  -webkit-transform: rotateZ(350deg);
  -moz-transform: rotateZ(350deg);
  -ms-transform: rotateZ(350deg);
  -o-transform: rotateZ(350deg);
}

.navfix{
  z-index: 1000;
  width: 100%;
  padding-left: 50px;
  padding-right: 50px;
  background-color: #ffffff42;
  backdrop-filter: blur(22px);
}

.mt-55{
  margin-top: 3rem
}

.bg-env{
  z-index: 10;
  transition: all .4s;
  -webkit-transition: all .4s;
  -moz-transition: all .4s;
  -ms-transition: all .4s;
  -o-transition: all .4s;
  background-color: #fff;
  display: none;
}

.bg-env-active{
  display: flex!important;
}

.bg-success{
  background-color: #03D688!important;
}

.font-22{
  font-size: 22px;
}

.left-0{
  left: 0;
}

.top-0{
  top: 0;
}

.ico-header{
  top: 0;
}

.abbout2{
  width: 50%;
}

.abbout{
  margin-right: 40px;
}

.animation-none{
  animation: none!important;
  -webkit-animation: none!important;
}

@keyframes floating {
  from {
    top: 180px;
  }

  50%{
    top: 190px;
  }
}

@keyframes floating2 {
  from {
    top: 345px;
  }

  50%{
    top: 360px;
  }
}

@keyframes rotating {
  from {
    transform: rotate(0deg);
    -webkit-transform: rotate(0deg);
    -moz-transform: rotate(0deg);
    -ms-transform: rotate(0deg);
    -o-transform: rotate(0deg);
}

  to{
    transform: rotate(359deg);
    -webkit-transform: rotate(359deg);
    -moz-transform: rotate(359deg);
    -ms-transform: rotate(359deg);
    -o-transform: rotate(359deg);
}
}


@keyframes height {
  from {
    height: 0px;
  }

  to{
    height: calc(100%-94px);
  }
}

@keyframes opac {
  from {
    opacity: 0;
  }
  to{
    opacity: 1;
  }
}

@keyframes transtop {
  from {
    transform: translateY(-100%);
    -webkit-transform: translateY(-100%);
    -moz-transform: translateY(-100%);
    -ms-transform: translateY(-100%);
    -o-transform: translateY(-100%);
}
  to{
    transform: translateY(0%);
    -webkit-transform: translateY(0%);
    -moz-transform: translateY(0%);
    -ms-transform: translateY(0%);
    -o-transform: translateY(0%);
}
}

.transopac{
  background-color: #000000b0;
  animation: .3s ease-in-out opac;
  -webkit-animation: .3s ease-in-out opac;
}

.topMid{
  animation: .6s ease-in-out transtop;
  -webkit-animation: .6s ease-in-out transtop;
  padding: 3rem;
}

.subhead{
  margin-bottom: 1.5rem
}

@media (max-width: 1400px) { 
  h1{
    font-size: 36px!important;
  }
  .subhead{
    font-size: 18px!important;
  }
  .link{
    display: none;
  }
}

@media (max-width: 1360px) { 
  #burger{
    display: flex!important;
  }
  #navbar{
    display: none!important;
  }
}

@media (max-width: 1200px) { 
  .contactctn{
    width: 100%!important;
  }
  .contact2{
    flex-direction: column;
    justify-content: center;
    height: 800px!important;
  }
  .map{
    margin-top: 40px;
  }
  h1{
    font-size: 30px!important;
  }
  .subhead{
    font-size: 14px!important;
  }
  .left-header{
    padding: 0!important;
  }
} 

@media (max-width: 1020px) { 
  .header-ctn{
    flex-direction: column-reverse;
    height: calc(100vh - 120px);
  }
  .right-header{
    transform: scale(0.8);
    width: 700px;
    margin-bottom: -50px;
  }
  .left-header{
    min-width: 90%!important;
  }
  .link{
    display: none;
  }
  .button-quote2{
    margin: 0!important;
  }
  .rs{
    margin-top: 0
  }
  h1{
    font-size: 35px!important;
  }
  .subhead{
    font-size: 18px!important;
  }
  .ceque{
    flex-direction: column;
    align-items: center;
  }
}

@media (max-width: 866px) { 
  .bgb{
    display: none;
  }
  h2{
    margin: auto;
  }
  .why{
    width: 300px!important;
    flex-direction: column;
    align-items: center;
  }
}

@media (max-width: 600px) { 
  .contactctn{
    padding: 10px;
  }
  #root{
    padding: 0!important;
    background-repeat: inherit;
    background-size: cover;
  }

  .react-tel-input .form-control{
    width: 210px!important;
  }
  #burger{
    padding: 0;
  }
  .avisswip{
    height: 410px;
  }
  .abbout{
    display: none;
  }
  .mt-55{
    margin-top: 0rem
  }
  .abbout2{
    width: 90%;
    margin: auto;
  }

  .button-quote2{
    margin: auto!important;
  }

  .ctn-logo{
    transform: scale(.8);
    -webkit-transform: scale(.8);
    -moz-transform: scale(.8);
    -ms-transform: scale(.8);
    -o-transform: scale(.8);
}
.rs{
  justify-content: center;
  margin-top: 20px;
}

.foot{
  font-size: 11px;
}

.cardprice{
  width: 90%!important;
  margin: auto;
}

.ctn-price{
  font-size: 14px;
}

.rs > a{
  padding-right: 8px;
}
.wraptest{
  width: 150px!important;
}
.wraptest > span{
  width: 75px!important;
  font-size: 15px!important;
}
.navig{
  margin-left: 16px;
}
.abbout2{
  text-align: center;
}
  .right-header{
    transform: scale(0.5);
    height: inherit;
  }
  .detailProject > div{
    flex-direction: column;
  }
  h1{
    font-size: 22px!important;
  }
  .subhead{
    font-size: 16px!important;
    margin-bottom: 0px!important;
  }
  .right, .left{
    width: 100%;
  }
  .right{
    margin-top: 20px;
    padding-left: 0!important;
  }
  .right-header{
    display: flex;
    align-items: flex-start;
    height: 100%;
  }
  .topMid{
    overflow: auto;
    left: 0;
    top: 0;
    position: fixed;
    padding: 2rem;
    height: 100vh;
    border-radius: 0;
    -webkit-border-radius: 0;
    -moz-border-radius: 0;
    -ms-border-radius: 0;
    -o-border-radius: 0;
}
  .ctn-proj{
    width: 100%!important;
  }
  .workc{
    padding: 1.5rem;
    width: 100%!important;
  }
  .swiper-slide{
    width: 100%!important;
  }
  #work{
    text-align: center;
  }
  .avis-card{
    margin: auto!important;
  }
}

@media (max-width: 400px) {
  .right-header {
    transform: scale(0.4);
  }
}

@media (max-width: 380px) {
  .footer-2{
    transform: scale(0.6);
    margin-left: -20px;
  }
  .contactctn{
    padding: 23px!important;
  }
}

@media (max-height: 600px) {
  .right-header {
    transform: scale(0.35)!important;
    -webkit-transform: scale(0.35)!important;
    -moz-transform: scale(0.35)!important;
    -ms-transform: scale(0.35)!important;
    -o-transform: scale(0.35)!important;
}
}

.swiper-wrapper{
  margin-bottom: 1rem
}

.mm-4{
  margin: 1.5rem
}

.pp-5{
  padding: 3rem
}