// change the theme
$theme-colors: (
  "primary": #0074d9,
  "danger": #FF6060,
  "info": #F6F6F6
);

// Import Bootstrap and its default variables
@import '~bootstrap/scss/bootstrap.scss';
    
.margin{
  margin-left: 100px;
  margin-right: 100px;
}

ul{
  margin-block-end: 0;
  margin-block-start: 0;
  padding-inline-start: 0;
}

.cursor-pointer{
  cursor: pointer;
}

.place-center{
  transform: translate(-50%, -50%);
  -webkit-transform: translate(-50%, -50%);
  -moz-transform: translate(-50%, -50%);
  -ms-transform: translate(-50%, -50%);
  -o-transform: translate(-50%, -50%);
}

@media only screen and (min-width: 1500px) {
  .thumb{
    width: 24%!important;
  }

  .ctn-thumb{
    gap: 1.3%!important;
  }
}

@media only screen and (max-width: 1200px) {
  .thumb{
    width: 49%!important;
  }

  .ctn-thumb{
    gap: 2% !important;
  }
}

@media only screen and (max-width: 800px) {
  .margin {
    margin-left: 10px;
    margin-right: 10px;
  }

  .thumb{
    width: 100%!important;
  }

  header > ul > li{
    font-size: 18px!important;
    padding: .4rem!important;
  }

  div > h2{
    left: 130px !important;
  }

  .branding{
    height: 125px!important;
  }

  .ctn-thumb{
    background-color: white!important;
    padding: 0!important;
  }

  .ctn-rate-picture{
    width: 100%;
    display: flex;
    align-items: center;
    flex-direction: row-reverse;
    justify-content: space-between;
  }

  .ctn-about-people{
    flex-direction: column!important;
  }

  .ctn-about-people > div{
    width: 100%!important;
    margin: 0!important;
  }

  header{
    padding-left: 0!important;
  }

  .errorSvg{
    width: 300px;
  }

  .errorMsg{
    font-size: 18px!important;
  }

  .ctn-about{
    margin: 0!important;
  }
}